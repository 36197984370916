<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Procurar</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Procurar"
              type="text"
              class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        id="table_deliberation"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
        :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'situation'">
          <b-badge :variant="statusVariant(statusVariantName(props.row.situation))">
            {{ statusVariantName(props.row.situation) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-link
              v-b-tooltip.hover.v-secondary
              title="Editar conta"
              :to="{
                name: 'deliberation-detailed',
                params: { id: props.row.id, data: props.row },
              }">
              <feather-icon icon="Edit2Icon" class="mr-50" />
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from './codeTable'
// import ModalUser from './ModalUser.vue'

export default {
  components: {
    // BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // BCol,
    // ModalUser,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'Nome',
          field: 'name',
        },
        {
          label: 'CRECI',
          field: 'creci',
        },
        {
          label: 'Telefone',
          field: 'phoneNumber',
        },
        {
          label: 'E-mail',
          field: 'emailAddress',
        },
        {
          label: 'Situação',
          field: 'situation',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      situation: [
        {
          1: 'Analise',
          2: 'Ativo',
          3: 'Rejeitado',
          4: 'Bloqueado',
          5: 'Suspenso',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Analise: 'light-primary',
        Ativo: 'light-success',
        Rejeitado: 'light-danger',
        Bloqueado: 'light-warning',
        Suspenso: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    statusVariantName() {
      const statusName = {
        /* eslint-disable key-spacing */
        2: 'Em Avaliação',
        1: 'Aprovado',
        3: 'Rejeitado',
        4: 'Cancelado',
        /* eslint-enable key-spacing */
      }

      return status => statusName[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    const parans = 'MaxResultCount=1000'
    const responseUser = await this.$http.get(
      `/api/services/app/UserBrokerProfiles/GetAllValidationPending?${parans}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    // eslint-disable-next-line no-shadow
    const res = responseUser.data.result.items.map(res => ({
      id: res.userBrokerProfile.id,
      creci: res.userBrokerProfile.creci,
      situation: res.userBrokerProfile.situation,
      name: res.userBrokerProfile.user.name,
      emailAddress: res.userBrokerProfile.user.emailAddress,
      phoneNumber: res.userBrokerProfile.user.phoneNumber,
      userName: res.userName,
    }))

    this.rows = res
  },

  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body {
    background-color: #f8f8f8 !important;
}
#table_deliberation tr {
  border: 0;
  display: block;
  margin: 15px 8px;
  border: 1px #fff solid;
  border-radius: 10px;
}

#table_deliberation td {
  padding: 5px;
}
#table_deliberation tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

#table_deliberation  .vgt-table.bordered td,  #table_deliberation .vgt-table.bordered th {
  border: #fff !important;
}

#table_deliberation table.vgt-table td {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}

#table_deliberation .vgt-left-align {
  text-align: left !important;
}

#table_deliberation table.vgt-table td {
  vertical-align: baseline !important;
  color: #6e6b7b;
}
#table_deliberation table.vgt-table thead {
  display: none;
}
#table_deliberation table.vgt-table tbody {
  font-size: 13px;
}
#table_deliberation table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}
#table_deliberation table tbody td { max-width: 285px; min-width: 285px; }
#table_deliberation table tbody td:first-child { max-width: 300px; min-width: 300px; }
#table_deliberation table tbody td:nth-child(2) { max-width: 150px; min-width: 150px; }
#table_deliberation  table tbody td:nth-child(3) { max-width: 140px; min-width: 140px; }
#table_deliberation table tbody td:nth-child(4) { max-width: 260px; min-width: 260px; }
#table_deliberation table tbody td:nth-child(5) { max-width: 100px; min-width: 100px; }
#table_deliberation table tbody td:nth-child(6) { max-width: 80px; min-width: 80px; float: right; margin-top: -50px; }
#table_deliberation a { color: #828282; }
#table_deliberation a:hover { color: #F8AC18; }
#table_deliberation td[colspan="7"] {
  text-align: center;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
</style>
